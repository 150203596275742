/* eslint-disable import/no-mutable-exports */
import {
    message as antdMessage,
    notification as antdNotification,
    App,
} from 'antd';

let message = antdMessage;
let notification = antdNotification;

/**
 * This component is used to escape the antd's static functions. 
 */
function EscapeAntd() {
    const staticFunctions = App.useApp();
    message = staticFunctions.message;
    notification = staticFunctions.notification;
    return null;
}

export {
    message,
    notification,
    EscapeAntd
};
