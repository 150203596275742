import React, { useState, useContext } from 'react';
import { Button, Tooltip } from 'apps/administration/components';
import { NOTIFICATION_INLINE } from 'modules/notification';
import { showError } from 'utils';
import { useAlerts } from 'hooks';
import { UploadOutlined } from '@ant-design/icons';
import Context from '../context';

const ExportButton = () => {
    const { fetchParams, fetchAction, columns, persistentFilters } = useContext(Context);
    const [loading, setLoading] = useState(false);
    const { start } = useAlerts(true);

    const onClick = () => {
        const exportColumns = {};
        columns
            .filter(item => !item.hide && item.key && !(item.exportable === false))
            .forEach((item) => {
                const name = item.sortBy || item.key;
                exportColumns[name] = encodeURIComponent(item.display);
            });

        setLoading(true);
        
        const { filters, sort } = fetchParams;
        return fetchAction(1, 500000, { ...filters, ...persistentFilters }, sort, {
            columns: {
                ...exportColumns
            },
            headers: {
                Accept: 'application/vnd.ms-excel',
            },
        })
            .then(() => {
                start(NOTIFICATION_INLINE.exportStart);
            })
            .finally(() => {
                setLoading(false);
            })
            .catch(showError);
    }; 

    return (
        <Tooltip
            placement="bottom"
            title="Export data in Excel (.xlsx format)"
        >

            <Button
                type="link"
                onClick={onClick}
                icon={<UploadOutlined />}
                loading={loading}
            >
                Export
            </Button>
        </Tooltip>
    );
};

export { ExportButton };
